/*eslint-disable*/
import React from "react";

// reactstrap components
import { Container } from "reactstrap";
// core comments

function PresentationHeader() {
  return (
    <>
      <div className="wrapper">
        <div
          className="page-header section-dark"
          style={{
            backgroundImage:
              "url(" + require("assets/img/site/image_0015.jpg") + ")",
          }}
        >
          <div className="content-center">
            <Container>
              <div className="title-brand">
                <br/><br/><br/><br/><br/><br/>
                {/*<h1 className="presentation-title">Личный сайт Оксаны Анисимовой</h1>*/}
                {/*<div className="fog-low">*/}
                {/*  <img*/}
                {/*    alt="..."*/}
                {/*    src={require("assets/img/sections/fog-low.png")}*/}
                {/*  />*/}
                {/*</div>*/}
                {/*<div className="fog-low right">*/}
                {/*  <img*/}
                {/*    alt="..."*/}
                {/*    src={require("assets/img/sections/fog-low.png")}*/}
                {/*  />*/}
                {/*</div>*/}
              </div>
              <h2 className="presentation-subtitle text-center">
               Рада приветствовать Вас на своем сайте. Меня зовут Оксана Анисимова
              </h2>
            </Container>
          </div>
        </div>
      </div>
    </>
  );
}

export default PresentationHeader;
