import React from "react";

// reactstrap components
import {Card, CardBody, Col, Container, Row,} from "reactstrap";

// core components
import PersonalLandingSliderSection from "./landing/PersonalLandingSliderSection";
import ColorNavbar from "../components/Navbars/ColorNavbar";
import PresentationHeader from "../components/Headers/PresentationHeader";

function PersonalLandingPage() {
    document.documentElement.classList.remove("nav-open");
    React.useEffect(() => {
        document.body.classList.add("landing-page");
        window.scrollTo(0, 0);
        document.body.scrollTop = 0;
        return function cleanup() {
            document.body.classList.remove("landing-page");
        };
    });
    return (
        <>
            <ColorNavbar />
            <PresentationHeader />
            <div className="wrapper">
                <div className="section text-center landing-section">
                    <Container>
                        <Row id={"about"}>
                            <Col className="ml-auto mr-auto" md="8">
                                <h2 className="title">Обо мне</h2>
                                <h5>
                                    Я - художник-скульптор, автор и разработчик объектов для сада. Окончила РАЖВиЗ Ильи Глазунова, факультет скульптуры, член Московского союза художников. Мои работы уже украшают множество частных садов и общественных ландшафтных пространств, а также учувствуют в тематических выставках, крупных фестивалях, таких как Дачное Царицыно в Москве.
                                </h5>
                                <br/>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <div className="section section-dark text-center landing-section">
                    <Container>
                        <h2 className="title" id={"desc"}>Описание</h2>
                        <Row>
                            <Col md="4">
                                <Card className="card-profile card-plain">
                                    <CardBody>
                                        <p className="card-description text-center">
                                            Здесь вы увидите коллекцию творческих работ, выполненных из дерева, металла и керамики. Одно из направлений, в котором я сейчас работаю - плетение фигур животных и арт - объектов для ландшафтного пространства из натуральной лозы. Природный материал с приятным колоритом и натуральной фактурой даёт возможность исполнить скульптурный образ, органично интегрирующийся в любой пейзаж.
                                        </p>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col md="4">
                                <Card className="card-profile card-plain">
                                    <CardBody>
                                        <p className="card-description text-center">
                                            Оригинальность сочетания профессионального подхода и аутентичность материала создают легкий и выразительный образ, ярко дополняющий среду, в которой находится скульптура. Полупрозрачность плетения создаёт неповторимую игру теней и мерцание в художественном объекте.
                                            Лёгкость материала, но при этом прочность фигур за счёт сварного каркаса внутри даёт возможность легко переносить скульптуры, ставить их в разные мизансцены сада
                                        </p>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col md="4">
                                <Card className="card-profile card-plain">
                                    <CardBody>
                                        <p className="card-description text-center">
                                            Керамика представлена декором ручной работы, который будет украшать сад, наполняя его особой атмосферой. Коллекция керамических работ, как и работ из дерева и металла будет регулярно пополняться.
                                            Надеюсь, что здесь вы увидите для себя понравившиеся изделия, которые сможете получить для своего сада в ближайшее время. Также всегда готова к сотрудничеству и индивидуальной разработке проекта для каждого обратившегося.
                                        </p>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <div className="section text-center landing-section">
                    <Container>
                        <Row id={"works"}>
                            <Col className="ml-auto mr-auto" md="8">
                                <h2 className="title">Мои работы</h2>
                                <PersonalLandingSliderSection/>
                            </Col>
                        </Row>
                    </Container>
                </div>

            </div>
            <div className="section text-center" id="contacts">
                <div className="container">
                    <div className="row" id={"contacts"}>
                        <div className="col-sm-12 col-md-4"><h3 className="title">Мои соцсети</h3></div>
                        <div className="col-sm-3 col-md-2"><a href="https://vk.com/club214223246"
                                                              className="btn-link btn-just-icon btn"><i className="fa fa-brands fa-vk mr-1"></i>VK</a></div>
                        <div className="col-sm-3 col-md-2"><a href="https://www.instagram.com/invites/contact/?igsh=33uod1c2p19k&utm_content=226k8fk"
                                                              className="btn-link btn-just-icon btn"><i
                            className="fa fa-brands fa-instagram mr-1"></i>Instagram</a></div>
                        <div className="col-sm-3 col-md-2"><a href="https://t.me/Sculpturalobjects"
                                                              className="btn-link btn-just-icon btn"><i
                            className="fa fa-brands fa-telegram mr-1"></i>Telegram</a></div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default PersonalLandingPage;
